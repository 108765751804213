import React, {useEffect, useState} from 'react';
import {Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import axios from "axios";
import AdapterDateLuxon from "@mui/lab/AdapterLuxon";

const DashboardFilter = (props) => {
    const [mode, setMode] = useState('hourly');
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());

    const datepicker = () => {
        if (props.url === 'monthly') {
            return <DatePicker label="Year"
                               views={['year']}
                               value={selectedDate}
                               onChange={(newDate) => {
                                   setSelectedDate(newDate);
                                   props.onChangeDate(newDate);
                               }}
                               renderInput={(params) =>
                                   <TextField fullWidth variant="filled" {...params} />}/>;
        } else if (props.url === 'daily') {
            return <DatePicker label="Month and Year"
                               views={['year', 'month']}
                               value={selectedDate}
                               onChange={(newDate) => {
                                   setSelectedDate(newDate);
                                   props.onChangeDate(newDate);
                               }}
                               renderInput={(params) =>
                                   <TextField fullWidth variant="filled" {...params} />}/>;
        } else {
            return <DatePicker label="Date"
                               value={selectedDate}
                               onChange={(newDate) => {
                                   setSelectedDate(newDate);
                                   props.onChangeDate(newDate);
                               }}
                               renderInput={(params) =>
                                   <TextField fullWidth variant="filled" {...params} />}/>;
        }
    };

    useEffect(() => {
        axios.get(`device`)
            .then(res => {
                setDevices(res.data.data);
            });
    }, []);

    useEffect(() => {
        setSelectedDevice(devices.length ? devices[0].id : "");
    }, [devices]);

    function deviceHandleChange(event) {
        setSelectedDevice(event.target.value);
        props.onChangeDevice(event.target.value);
    }

    function modeHandleChange(event) {
        setMode(event.target.value);
        props.onChangeMode(event.target.value);
    }

    return (
        <Grid sx={{
            alignItems: "center"
        }} container spacing={2} mb={2}>
            <Grid item xs={12} md={4}>
                <FormControl variant="filled" sx={{width: 1}}>
                    <InputLabel id="demo-simple-select-filled-label">Device</InputLabel>
                    <Select sx={{
                        width: "100%"
                    }} labelId="device-id-label"
                            id="device-id"
                            value={selectedDevice}
                            onChange={deviceHandleChange}>
                        {devices.map(device => (
                            <MenuItem key={device.id} value={device.id}>{device.device_name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDateLuxon}>
                    {datepicker()}
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
                <Box sx={{
                    textAlign: "right"
                }}>
                    <Button variant={mode === 'hourly' ? "contained" : "text"} onClick={modeHandleChange} value="hourly">Hourly</Button>
                    <Button variant={mode === 'daily' ? "contained" : "text"} onClick={modeHandleChange} value="daily">Daily</Button>
                    <Button variant={mode === 'monthly' ? "contained" : "text"} onClick={modeHandleChange} value="monthly">Monthly</Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default DashboardFilter;
