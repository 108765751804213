import React, {useState} from 'react';
import {
    Card, CardContent,
    CardHeader, Divider, Table, TableBody,
    TableCell, TableHead,
    TableRow
} from "@mui/material";

const CardSummary = (props) => {
    const rows = [
        {
            name: "Energy Used Payment",
            unit: "Rp",
            value: "Rp 1 000 000,00"
        },
        {
            name: "Energy Produced From Solar Panel",
            unit: "Rp",
            value: "Rp 800 000,00"
        },
        {
            name: "Energy Saving",
            unit: "Rp",
            value: "Rp 200 000,00"
        },
    ]

    return (
        <Card>
            <CardHeader title={'Summary'}/>
            <Table>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
};

export default CardSummary;
