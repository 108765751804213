import {createTheme} from '@mui/material';
import shadows from './shadows';
import typography from './typography';
import {amber, deepOrange, grey} from '@mui/material/colors';

/***
 *
 * 095256, 087F8C, 5AAA95, 86A873, BB9F06
 *
 */

const getDesignTokens = (mode) => ({
    palette: {
        mode,
        primary: {
            ...amber,
            ...(mode === 'dark' && {
                main: amber[300],
            }),
        },
        ...(mode === 'dark' && {
            background: {
                default: deepOrange[900],
                paper: deepOrange[900],
            },
        }),
        text: {
            ...(mode === 'light' ? {
                primary: grey[900],
                secondary: grey[800],
            } : {
                primary: '#fff',
                secondary: grey[500],
            }),
        },
    },
    shadows,
    typography
});

const theme = createTheme(getDesignTokens('light'));

export default theme;
