import React, {useState} from 'react';
import {
    Avatar,
    Box, Card,
    CardContent, CardHeader, Grid, Typography
} from "@mui/material";
import {DateTime} from "luxon";
import DashboardFilter from "../dashboard/DashboardFilter";
import CardInfo from "../dashboard/CardInfo";
import EnergyChart from "../dashboard/EnergyChart";
import CardWeather from "../dashboard/CardWeather";
import CardSummary from "../dashboard/CardSummary";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

const Dashboard = () => {
    const [mode, setMode] = useState("hourly");
    const [device, setDevice] = useState(1);
    const [datepicker, setDatepicker] = useState(DateTime.now());

    const handleChangeMode = (newValue) => {
        setMode(newValue);
    };

    const handleChangeDevice = (newValue) => {
        setDevice(newValue);
    };

    const handleChangeDate = (newValue) => {
        setDatepicker(newValue);
    };

    return (
        <Box m={3}>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px"
            }}>
                <Typography sx={{
                    flexGrow: 1
                }} variant="h4">
                    Dashboard
                </Typography>
                <Avatar alt="Rubyalam MS"/>
            </Box>
            <DashboardFilter onChangeDevice={(newValue) => handleChangeDevice(newValue)}
                             onChangeDate={(newValue) => handleChangeDate(newValue)}
                             onChangeMode={(newValue) => handleChangeMode(newValue)}
                             url={mode}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={6} md={6} lg={3}>
                            <CardInfo
                                url="device/energy_produced"
                                title="Solar Energy Produced"
                                device={device} date={datepicker}
                                source="PLTS" mode={mode}
                                io_state={0}
                                color="#095256"/>
                        </Grid>
                        <Grid item xs={6} md={6} lg={3}>
                            <CardInfo
                                url="device/energy_produced"
                                title="Electricity Used"
                                device={device} date={datepicker}
                                source="PLN" mode={mode}
                                io_state={0}
                                color="#087F8C"/>
                        </Grid>
                        <Grid item xs={6} md={6} lg={3}>
                            <CardInfo
                                url="device/energy_exported"
                                title="Energy Exported"
                                device={device} date={datepicker}
                                source="PLN" mode={mode}
                                io_state={1}
                                color="#5AAA95"/>
                        </Grid>
                        <Grid item xs={6} md={6} lg={3}>
                            <CardInfo
                                url="device/energy_used"
                                title="Energy Used"
                                device={device} date={datepicker}
                                mode={mode}
                                io_state={1}
                                color="#86A873"/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Card>
                                <PerfectScrollbar>
                                    <CardHeader title="Energy Produced"/>
                                    <CardContent>
                                        <EnergyChart url={mode}
                                                     device_id={device}
                                                     datepicker={datepicker}
                                                     io_state={0}/>
                                    </CardContent>
                                </PerfectScrollbar>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                            <CardWeather/>
                            <CardSummary/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Card>
                                <PerfectScrollbar>
                                    <CardHeader title="Energy Exported"/>
                                    <CardContent>
                                        <EnergyChart url={mode}
                                                     device_id={device}
                                                     datepicker={datepicker}
                                                     energy_source="PLN"
                                                     io_state={1}/>
                                    </CardContent>
                                </PerfectScrollbar>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Card>
                                <PerfectScrollbar>
                                    <CardHeader title="Energy Used"/>
                                    <CardContent>
                                        <EnergyChart url={mode}
                                                     device_id={device}
                                                     datepicker={datepicker}
                                                     io_state={1}/>
                                    </CardContent>
                                </PerfectScrollbar>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
