import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import axios from "axios";
import CountUp from "react-countup";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const CardInfo = (props) => {
    const [value, setValue] = useState(0);
    const [detail, setDetail] = useState(0);

    const checkValue = (detail) => {
        let before = "Yesterday";
        if (props.mode === "daily") before = "Last Month"
        else if (props.mode === "monthly") before = "Last Year"

        if (detail < 0)
            return <><ArrowDropDownIcon/>{Math.abs(detail.toFixed(2))}% From {before}</>
        else
            return <><ArrowDropUpIcon/>{Math.abs(detail.toFixed(2))}% From {before}</>
    }

    function updateValue() {
        let params = {
            device: props.device,
            date: props.date.toISODate(),
            mode: props.mode,
            energy_source_name: props.source,
            io_state: props.io_state
        }

        axios.post(props.url, params)
            .then(function (res) {
                setValue(res.data.value);
                setDetail(res.data.detail);
            })
            .catch(function (error) {
                setValue(999999);
            });
    }

    useEffect(() => {
        updateValue();
    }, [props]);

    return (
        <Card sx={{
            backgroundColor: props.color,
            borderRadius: 3
        }}>
            <CardContent sx={{
                textAlign: "center"
            }}>
                <Box sx={{
                    display: "flex",
                    height: "100%"
                }} color="white">
                    <Box sx={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <DashboardIcon sx={{fontSize: 48}}/>
                    </Box>
                    <Box sx={{
                        width: "100%"
                    }}>
                        <Typography variant="h5">{props.title}</Typography>
                        <Typography variant="test">
                            <CountUp start={0}
                                     end={value}
                                     duration={2}
                                     separator=" "
                                     decimals={2}
                                     decimal=","
                                     suffix=" kWh"
                            />
                        </Typography>
                        <Typography>{checkValue(detail)}</Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default CardInfo;
