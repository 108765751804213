import React from 'react';
import {Card, CardContent, CardHeader, Link} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ListWeather from "./ListWeather";

const CardWeather = () => {
    return (
        <Card sx={{
            marginBottom: 2
        }}>
            <CardHeader title="Weather"
                        action={
                            <Link>Pasuruan, Jawa Timur <LocationOnIcon/></Link>
                        }/>
            <CardContent>
                <ListWeather/>
            </CardContent>
        </Card>
    );
};

export default CardWeather;
