import React from 'react';
import {
    Box,
    Grid,
    Typography
} from "@mui/material";
import {WiDaySunnyOvercast} from "react-icons/wi";

const ListWeather = () => {
    return (
        <Grid container spacing={1}>
            <Grid item xs sx={{
                display: "flex",
                justifyContent: "center"
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    borderRadius: 5
                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <WiDaySunnyOvercast size={48}/>
                    </Box>
                    <Box width={1} ml={1}>
                        <Typography variant="h4">30°C</Typography>
                        <Typography variant="h6">07:00</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs sx={{
                display: "flex",
                justifyContent: "center"
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-around",
                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <WiDaySunnyOvercast size={48}/>
                    </Box>
                    <Box width={1} ml={1}>
                        <Typography variant="h4">30°C</Typography>
                        <Typography variant="h6">08:00</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs sx={{
                display: "flex",
                justifyContent: "center"
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    borderRadius: 5
                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <WiDaySunnyOvercast size={48}/>
                    </Box>
                    <Box width={1} ml={1}>
                        <Typography variant="h4">30°C</Typography>
                        <Typography variant="h6">09:00</Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ListWeather;
