import React from 'react';
import {makeStyles} from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const MainAppbar = ({onMobileNavOpen, ...rest}) => {
    const classes = useStyles();

    return (
        <AppBar position="static">
            <Toolbar>
                <Brightness7Icon/>
                <Typography variant="h5" className={classes.title} ml={1}>
                    Smart Solar System
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default MainAppbar;
