import React, {Fragment, useEffect, useState} from 'react';
import axios from "axios";
import {Box, Card, Divider, Paper, Typography} from "@mui/material";
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label,
    LabelList,
    Line, Cell
} from 'recharts';

const EnergyChart = ({url, device_id, datepicker, io_state, energy_source}) => {
    const [data, setData] = useState([]);

    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <Paper>{`${label} : This is test case`}</Paper>
            );
        }

        return null;
    };

    let timer;

    function setUpdate() {
        let params = {
            device_id: device_id,
            datepicker: datepicker.toISODate(),
            io_state: io_state,
            energy_source: energy_source
        };

        axios.get(url, {params})
            .then(function (res) {
                setData(res.data.data);
            });
    }

    function getBar(io_state, energy_source = null) {
        let index = 0;
        if (io_state === 1) {
            if (energy_source === null) {
                index = 1;
            } else {
                index = 2;
            }
        }
        const bars = [
            [
                {
                    dataKey: 'y',
                    stackId: 'e',
                    fill: '#095256',
                    name: 'PLTS'
                },
                {
                    dataKey: 'y2',
                    stackId: 'e1',
                    fill: '#BB9F06',
                    name: 'PLN'
                },
            ],
            [
                {
                    dataKey: 'y',
                    stackId: 'e',
                    fill: '#087f8c',
                    name: 'Gedung'
                },
            ],
            [
                {
                    dataKey: 'y',
                    stackId: 'e',
                    fill: '#86a873',
                    name: 'PLTS to PLN'
                },
            ]
        ]

        return bars[index]
    }

    useEffect(() => {
        setUpdate();

        timer = setInterval(() => {
            setUpdate();
        }, 60000);

        return () => clearInterval(timer);
    }, [url, device_id, datepicker])

    return (
        <Box sx={{
            height: "100%"
        }} m={2}>
            <ResponsiveContainer width="100%" height="100%" minWidth={600} minHeight={350}>
                <ComposedChart
                    data={data}
                    margin={{
                        top: 10,
                        right: 5,
                        left: 5,
                        bottom: 5,
                    }}>
                    <XAxis dataKey="x">
                        <Label value="Time" offset={-5} position="insideBottom"/>
                    </XAxis>
                    <YAxis domain={[0, dataMax => (Math.ceil(dataMax / 4) * 4)]}
                           allowDataOverflow>
                        <Label value="Energy (kWh)" position="insideLeft" angle={-90}/>
                    </YAxis>
                    <CartesianGrid vertical={false}/>
                    <Tooltip/>
                    {/*<Tooltip content={<CustomTooltip/>}/>*/}
                    <Legend verticalAlign="top" height={36}/>
                    {getBar(io_state, energy_source).map((bar) => {
                        return <Bar key={'bar_' + bar.dataKey}
                                    dataKey={bar.dataKey}
                                    stackId={bar.stackId}
                                    radius={[15, 15, 15, 15]}
                                    fill={bar.fill}
                                    name={bar.name}>
                            {/*<LabelList key={'label_list_' + bar.dataKey} dataKey={bar.dataKey}*/}
                            {/*           position="centerTop" angle={-90} fontSize={10}*/}
                            {/*           fill="white"/>*/}
                            <LabelList key={'label_list_' + bar.dataKey} dataKey={bar.dataKey}
                                       position="top" fontSize={9} fontWeight={700}/>
                        </Bar>
                    })}
                </ComposedChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default EnergyChart;
