import React, {useState} from 'react';
import {makeStyles} from "@mui/styles";
import MainAppbar from "./MainAppbar";
import Dashboard from "../pages/Dashboard";
import {Box, Container, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import LeftDrawer from "./LeftDrawer";

const drawerWidth = 75;

const MainLayout = () => {
    return (
        <div>
            <LeftDrawer drawerWidth={drawerWidth}/>
            <Box sx={{
                paddingLeft: {xs: 0, md: drawerWidth + 'px'},
                minHeight: 'calc(100vh - 96px)'
            }}>
                <Dashboard/>
            </Box>
            <Box sx={{
                textAlign: "right"
            }} m={3}>
                <Typography variant="h6">
                    Copyright PT. Santinilestari Energi Indonesia
                </Typography>
            </Box>
        </div>
    );
};

export default MainLayout;
