import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import MainLayout from "./layouts/MainLayout";
import {useMediaQuery} from "@mui/material";
import theme from "./theme";
import "@fontsource/nunito";
import shadows from "./theme/shadows";
import typography from "./theme/typography";

function Welcome() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
                shadows,
                typography
            }),
        [prefersDarkMode],
    );

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <MainLayout/>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default Welcome;

if (document.getElementById('app')) {
    ReactDOM.render(<Welcome/>, document.getElementById('app'));
}
