import React from 'react';
import {Box, Button, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Paper} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoIcon from '@mui/icons-material/Google';

const mainMenu = [
    {
        name: "Dashboard",
        icon: DashboardIcon
    }
]

const LeftDrawer = (props) => {
    return (
        <Drawer sx={{
            width: props.drawerWidth,
            display: { xs: 'none', md: 'block'},
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: props.drawerWidth,
                boxSizing: 'border-box',
            },
        }} variant="permanent" anchor="left">
            <Box sx={{
                m: 1
            }}>
                <List>
                    <ListItem sx={{
                        mx: "auto"
                    }} button key="Logo">
                        <LogoIcon/>
                    </ListItem>
                    <Divider/>
                    {mainMenu.map((menu) => (
                        <ListItem sx={{
                            mx: "auto"
                        }} button key={menu.name}>
                            <DashboardIcon/>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    );
};

export default LeftDrawer;
